import { config } from '../config';
import Web3 from 'web3';




export const getProviderOrSigner = () => {
    const provider = new Web3(new Web3(window.ethereum));
    return {
        provider
    }
}


export const getContractInstance = ({ provider, contractAddress, contractAbi }) => {
    const contract = new provider.eth.Contract(contractAbi, contractAddress);
    return contract
}