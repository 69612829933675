import React, { useState, useEffect, useCallback } from 'react';
import './App.css'; import { Label, Grid, Segment, Button } from 'semantic-ui-react';
import contractAbi from "./constants/Abi/contractAbi.json"
import tokenAbi from "./constants/Abi/tokenAbi.json"
import { getContractInstance, getProviderOrSigner } from './utils/common';
import toast, { Toaster } from 'react-hot-toast';
const FUNCTIONS = [{ label: "User Tokens Balance", functionName: "balanceOf", }, { label: "Total Invested ETH", functionName: "invested", }, { label: "Total Received Tokens", functionName: "received", }, { label: "Tokens Price", functionName: "getCurrentPrice", }, { label: "Sale End Time", functionName: "saleEndTime", }];

function App() {
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [investment, setInvestment] = useState(0)
  const [connectedAccount, setConnectedAccount] = useState('');
  const [contractFunctions, setContractFunctions] = useState(FUNCTIONS);
  const [error, setError] = useState(null)
  const [values, setValues] = useState({
    balanceOf: "",
    minInvestment: "",
    maxInvestment: "",
    invested: "",
    received: "",
    getCurrentPrice: "",
    saleEndTime: ""
  });

  const contractAddress = '0xe8eEE524D1fD7281D95697D751Dd705c866DdEb5'; // Replace with the actual contract address
  const fetchContractInfo = async () => {
    try {
      const { provider } = getProviderOrSigner();
      const contract = getContractInstance({ provider, contractAddress, contractAbi });
      const name = await contract.methods.name().call();
      const symbol = await contract.methods.symbol().call();
      const supply = await contract.methods.totalSupply().call();
      setTokenName(name);
      setTokenSymbol(symbol);
      setTotalSupply(supply.toString());
    } catch (error) {
      console.error('Error fetching contract information:', error);
    }
  }
  const fetchContractFunctions = useCallback(async () => {
    try {
      if (connectedAccount) {
        const { provider } = getProviderOrSigner();
        const contract = getContractInstance({ provider, contractAddress: "0xe8eEE524D1fD7281D95697D751Dd705c866DdEb5", contractAbi: tokenAbi });
        const balanceOf = await contract.methods.balanceOf(connectedAccount).call();
        const userInfo = await contract.methods.userInfo(connectedAccount).call();
        const tokenPrice = await contract.methods.getCurrentPrice().call();
        const saleEndTime = await contract.methods.saleEndTime().call();
        const minInvestment = await contract.methods.minInvestment().call();
        const maxInvestment = await contract.methods.maxInvestment().call();

        setValues((prev) => {
          return {
            ...prev,
            minInvestment: Number(minInvestment.toString()) / (10 ** 18),
            maxInvestment: Number(maxInvestment.toString()) / (10 ** 18),
            balanceOf,
            getCurrentPrice: tokenPrice,
            saleEndTime,
            invested: userInfo[1],
            received: userInfo[0]
          }
        })
      }
    } catch (error) { console.error('Error fetching contract functions:', error); }
  }, [connectedAccount]);
  const connectWallet = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const connectedAccount = accounts[0]; setConnectedAccount(connectedAccount);
      localStorage.setItem("account", JSON.stringify(connectedAccount))
    } catch (error) {
      console.error('Failed to connect to MetaMask:', error);
    }
  };



  const disconnectWallet = () => {
    localStorage.clear();
    setConnectedAccount(null)
  }
  console.log(values, "VALUES")

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));

    if (account) { setConnectedAccount(account) }
  }, [])
  useEffect(() => { fetchContractInfo(); fetchContractFunctions(); }, [fetchContractFunctions]);

  const validateInvest = (value) => {
    if (value > values.maxInvestment || value < values.minInvestment) {
      setError(`Value should be between ${values.minInvestment} and ${values.maxInvestment}`)
      return false
    }
    setError(null);
    return true
  }

  const investSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const validate = validateInvest((investment));
      if (!validate) {
        setLoading(false)
        return
      }
      const { provider } = getProviderOrSigner();
      const contract = getContractInstance({ provider, contractAddress: "0xe8eEE524D1fD7281D95697D751Dd705c866DdEb5", contractAbi: tokenAbi });
      const response = await contract.methods.invest().send({ value: investment * 10 ** 18, from: connectedAccount });
      toast.success("Successful");
      setLoading(false);
    } catch (err) {
      console.log(err)
      setLoading(false);

    }
  }


  return (
    <div className="App">
      <Toaster />
      <header className="App-header">
        <h1>DeFi Global Coin</h1>
        <Segment compact>
          <Grid columns={2}>
            <Grid.Column>
              <Label> Name: {tokenName}</Label>
              <span>{tokenName}</span>
            </Grid.Column> <Grid.Column>
              <Label> Symbol: {tokenSymbol}</Label>
              <span>{tokenSymbol}</span>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment compact>
          <Grid columns={1}>
            <Grid.Column>
              <Label>Total Supply: {totalSupply / (10 ** 18)}</Label>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment compact>
          <Label>Contract Functions:</Label>
          <div className="function-list" style={{ display: 'flex' }}>
            {contractFunctions.map((func, index) => {
              return <>
                <Segment compact key={index} className="function-item" style={{ marginRight: '10px', color: 'black' }}>
                  {func.label.replace("Tokens", tokenSymbol)}
                  <p style={{ color: 'black' }}>

                    {func.functionName === 'saleEndTime' ?
                      new Date(parseInt(values[func.functionName]) * 1000).toDateString() :
                      values[func.functionName].toString() / (10 ** 18)}
                  </p>
                </Segment>
              </>
            })}
          </div>
        </Segment>
        {connectedAccount && <form onSubmit={investSubmit} style={{ display: "flex", gap: "10px", marginBottom: "10px", alignItems: "center", justifyContent: 'center' }}>
          <input type='number' value={investment} onChange={(e) => setInvestment(Number(e?.target?.value))}
          // max={values.maxInvestment}
          // min={values.minInvestment}

          />
          <Button loading={loading} type='submit'>Invest</Button>
        </form>
        }
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button onClick={connectedAccount ? disconnectWallet : connectWallet}>{connectedAccount ? "Disconnect" : "Connect to MetaMask"}</button>
        <p>Connected Account: {connectedAccount ? connectedAccount : 'Not connected'}</p>
        <hr />
        <a className="App-link" href="https://DeFiGlobals.com" target="_blank" rel="noopener noreferrer" > Https://DeFiGlobals.com </a>
      </header>
    </div>
  );
};


export default App;